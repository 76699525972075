import type { JSX } from 'react';
import { Brands as BrandsComponents } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type BrandsProps = SliceComponentProps<Content.BrandsSlice>;

const Brands = ({ slice }: BrandsProps): JSX.Element => (
  <BrandsComponents {...slice} />
);

export default Brands;
